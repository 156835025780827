const footercontent = {
  business: "Concierge Wellness",
  address1: "1315 Lords Hill Dr",
  address2: "Fountain, Colorado 80817",
  phone: "719-247-2207",
  email: "christian@conciergewellnessandpt.com",
  insta: "https://www.instagram.com/concierge_wellness/",
};

export default footercontent;
